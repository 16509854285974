import { useEffect, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ApiContext } from "../../contexts/ApiContext";
import Banner from "../../component/banner/Banner";
import Filter from "../../component/filter/Filter";
import SearchInput from '../../component/search/SearchInput';
import TitleSection from "../../component/titleSection/TitleSection";
import ProductSelection from "../../component/product/ProductSelecction";
import Spinner from '../../component/spinner/Spinner';

export default function RepairView() {
    const { productById, fetchProductById,emptyProductById, emptyProductByBrand} = useContext(ApiContext);
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const brandParams = searchParams.get('brand');
    const productParams = searchParams.get('product');

    useEffect(() => {
        emptyProductByBrand()
        window.scrollTo(0, 0);
        return () => {
            emptyProductById()
            emptyProductByBrand()
        };
    }, []); // eslint-disable-line

    const handleProductSelect = async (productId) => {
        try {
            setLoading(true);
            await fetchProductById(productId);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    return (
        <>
            <Banner banner={banner}/>
            <div className="containerPortal paddingSection">
                <TitleSection title={'Repará tus Equipos'} />
                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-2">
                        <div style={{ paddingBottom: '2rem' }} >
                            <h5>Ingrese o Seleccione su Modelo</h5>
                            <SearchInput />
                        </div>
                        <Filter slugBrandReceived={brandParams} slugProductReceived={productParams} onProductSelect={handleProductSelect} />
                    </div>
                    <div className="col-sm-12 col-md-9 col-lg-10">
                        {(!productParams && !productById) ? (
                            <h3 style={{ textAlign: 'center', margin: '2rem 0rem' }}>
                                Seleccione una Marca y un Producto para ver Todo
                            </h3>
                        ) : (
                            
                            loading ? (<Spinner />) : productById && (<ProductSelection productSlug={productById}  sectionOrder={['equipo', 'repuestos', 'kit', 'manuales', 'accesorios']}/>)
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const banner = 
[
    {id: 1, title: 'REPARÁ TU EQUIPOS', img: '../../../assets/img/banner/bannerRepair.jpg', alt: 'Imagen Banner' },
];