import React, { useEffect, useState } from 'react';
import { getSolutionsToPoduct } from "../../service/ApiServicioTecnico";
import AccordionsGroup from '../accordion/AccordionsGroup';
import styled from 'styled-components';
import ModalMailTurno from '../modal/ModalMailTurno';

export default function RepairSelection({ productSlug }) {
    
    const [failure, setFailure] = useState(null);
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
        if (productSlug) {
            const fetchSolutions = async () => {                
                const response = await getSolutionsToPoduct(productSlug.producto.id);                
                setFailure(response);
            };
            fetchSolutions();
        }
    }, [productSlug]);

    if (!productSlug) {
        return <h3 style={{ textAlign: 'center', margin: '2rem 0rem' }}>Seleccione una Marca y un Producto para ver su ficha técnica</h3>;
    }

    const handleShowModal = () => setShowModal(true); // Función para mostrar el modal
    const handleCloseModal = () => setShowModal(false); // Función para cerrar el modal

    const { producto } = productSlug;

    return (
        <StyledRepairSelection>
            <div className="containerRepair">
                <div className="row" style={{width: '100%'}}>
                    <div className="col-sm-3 col-md-6 col-lg-4" >
                        <div className="row">
                            <div className="col-md-6 col-lg-12">
                                <img
                                    src={`https://leiten.com.ar/storage/${producto.imagen_grande.replace(/^public\//, '')}`}
                                    style={{ padding: '1rem 0rem', width: '100%' }}
                                    alt={`Imagen de ${producto.nombre_producto}`}
                                />
                                <div style={{textAlign: 'center'}}>
                                    {/* <button className='btn btn-secondary buttonSecondary'>Presupuesto Mantenimiento Standard</button>
                                    <button className='btn btn-secondary buttonSecondary'>Mantenimiento Preventivo</button> */}
                                    <button 
                                        className='btn btn-secondary buttonSecondary'
                                        onClick={handleShowModal} // Al hacer clic, muestra el modal
                                    >
                                        Solicitar Turno para Reparación
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="col-sm-3 col-md-6 col-lg-3">
                            <button className='btn btn-primary' style={{width: '100%', height: '400px'}}>boton de prueba</button>
                    </div> */}

                    <div className="col-sm-6 col-md-12 col-lg-8">
                        {failure && failure.length > 0 ? 
                        <AccordionsGroup listFailure={failure}/>
                        : <h5 style={{ marginTop: '2rem' }}>No hay Soluciones disponibles para este producto</h5>}
                    </div>
                    
                </div>
               
            </div>
            <ModalMailTurno show={showModal} handleClose={handleCloseModal} title={'Solicitar Turno por una Reparación'}/>
        </StyledRepairSelection>

    );
}

const StyledRepairSelection = styled.div`
    .containerRepair{
        //padding: 0rem 0rem 0rem 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        @media (min-width: 992px){
            margin-top: 0rem;
        }
    }
    .buttonSecondary {
        margin: 0.3rem 0rem;
        width: 90%;
        font-weight: 700;
        font-size: 1.1rem;
    }
`