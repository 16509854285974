import { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ApiContext } from "../../contexts/ApiContext";
import Banner from "../../component/banner/Banner";
import Filter from "../../component/filter/Filter";
import SearchInput from '../../component/search/SearchInput';
import TitleSection from "../../component/titleSection/TitleSection";
import ProductSelection from "../../component/product/ProductSelecction";

export default function AccessoryView() {
    const { productById, fetchProductById, emptyProductById, emptyProductByBrand } = useContext(ApiContext);
    const [searchParams] = useSearchParams();
    const brandParams = searchParams.get('brand');
    const productParams = searchParams.get('product');

    useEffect(() => {
        emptyProductByBrand()
        emptyProductById()

        window.scrollTo(0, 0);
        return () => {
            emptyProductByBrand()
            emptyProductById()
        };
    }, []); //es-lint-disable-line

    const handleProductSelect = async (productId) => {
        try {
            await fetchProductById(productId);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    return (
        <>
            <Banner banner={banner}/>
            <div className="containerPortal paddingSection">
                <TitleSection title={'Encontra el Accesorio para tu Equipo'} />
                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-2">
                        <div style={{ paddingBottom: '2rem' }} >
                            <h5>Ingrese o Seleccione su Modelo</h5>
                            <SearchInput />
                        </div>
                        <Filter slugBrandReceived={brandParams} slugProductReceived={productParams} onProductSelect={handleProductSelect} />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-10">
                        {(!productParams && !productById) ? (
                            <h3 style={{ textAlign: 'center', margin: '2rem 0rem' }}>
                                Seleccione una Marca y un Producto para ver Todo
                            </h3>
                        ):(
                            productById && (<ProductSelection productSlug={productById}  sectionOrder={['accesorios', 'equipo', 'repuestos', 'kit', 'manuales']}/>)

                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const banner = 
[
    {id: 1, title: 'ACCESORIOS', img: '../../../assets/img/banner/bannerAccesory.jpg', alt: 'Imagen Banner' },
];