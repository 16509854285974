import React, { useEffect, useContext } from 'react';
import { useSearchParams } from "react-router-dom";
import { ApiContext } from '../../contexts/ApiContext';
import Filter from '../../component/filter/Filter';
import Banner from '../../component/banner/Banner';
import TitleSection from '../../component/titleSection/TitleSection';
import SearchInput from '../../component/search/SearchInput';
import ProductSelection from '../../component/product/ProductSelecction';

export default function SpartPartView() {

    const {productById, fetchProductById,emptyProductById, emptyProductByBrand} = useContext(ApiContext);

    const [searchParams] = useSearchParams();
    const brandParams = searchParams.get('brand');
    const productParams = searchParams.get('product');

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            emptyProductByBrand()
            emptyProductById()
        };
    }, []);

    const handleProductSelect = async (productId) => {
        try {
            await fetchProductById(productId);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    return (
        <>
            <Banner banner={banner}/>
            <div className="containerPortal paddingSection">
            <TitleSection title={'Encontra todos los Kit para tu Equipo'} />

                <div className="row">
                    <div className="col-sm-12 col-md-3 col-xl-2">
                        <div style={{ paddingBottom: '2rem' }} >
                            <h5>Ingrese o Seleccione su Modelo</h5>
                            <SearchInput />
                        </div>
                        <Filter slugBrandReceived={brandParams} slugProductReceived={productParams} onProductSelect={handleProductSelect}/>
                    </div>
                    <div className="col-sm-12 col-md-9 col-xl-10">
                        {(!productParams && !productById) ? (
                            <h3 style={{ textAlign: 'center', margin: '2rem 0rem' }}>
                                Seleccione una Marca y un Producto para ver Todo
                            </h3>
                        ) : (
                            productById && (<ProductSelection productSlug={productById}  sectionOrder={['kit', 'equipo', 'repuestos', 'manuales', 'accesorios']}/>)
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const banner = 
[
    {id: 1, title: 'Kits de Mantenimiento', img: '../../../assets/img/banner/bannerKit.jpg', alt: 'Imagen Banner' },
];
